import fi from "date-fns/locale/fi";
import enGB from "date-fns/locale/en-GB";

const i18n = {
  fi: {
    // Only one item MUST have the "default: true" key
    default: true,
    path: "",
    locale: `fi-FI`,
    datePickerLocale: fi.default,
    dateFormat: `d.M.yyyy`,
    cloudBedsDateFormat: "d.m.Y",
    siteLanguage: `fi`,
    ogLanguage: `fi_FI`,
    switchText: "Suomeksi",
    defaultTitle: `Lodge 67˚N`,
    defaultDescription: `Luksusmajoitusta Ylläksellä`,
  },
  en: {
    path: `en`,
    locale: `en-US`,
    datePickerLocale: enGB.default,
    dateFormat: `dd/MM/yyyy`,
    cloudBedsDateFormat: "d/m/Y",
    siteLanguage: `en`,
    ogLanguage: `en_US`,
    switchText: "In English",
    defaultTitle: `Lodge 67˚N`,
    defaultDescription: `Cozy luxury lodge at the heart of Finnish Lapland`,
  },
};

export default i18n;
