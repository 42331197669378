import React, { useState } from "react";
import useTranslations from "./useTranslations";
import { LocaleContext } from "./layout";
import locales from "../../config/i18n";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Returns the start and end dates we use in reserve forms
const dates = () => {
  const [initStart, initEnd] = [new Date(), new Date()];
  initStart.setDate(initStart.getDate() + 1);
  initEnd.setDate(initEnd.getDate() + 5);

  return { initStart, initEnd };
};

const Reserve = () => {
  const { reserveTitle, reserveButton, reserveCheckIn, reserveCheckOut } =
    useTranslations();

  // Grab the locale (passed through context) from the Context Provider. We need
  // it for the date format per locale.
  const { locale } = React.useContext(LocaleContext);
  const { initStart, initEnd } = dates();

  // Store selected dates in react state. These are currently used only in
  // filtering dates in the picker (end cannot be before start etc.);
  // the form is sent to CloudBeds as a standard form POST
  const [startDate, setStartDate] = useState(initStart);
  const [endDate, setEndDate] = useState(initEnd);

  const loc = locales[locale];

  return (
    <div className="reserve-form">
      <div>
        <p className="title is-3 is-hidden-mobile">{reserveTitle}</p>
        <form
          method="POST"
          action="https://hotels.cloudbeds.com/reservas/1rfZCc"
          target="_blank"
        >
          <input
            type="hidden"
            name="date_format"
            value={loc.cloudBedsDateFormat}
          />
          <div className="columns">
            <div className="column  is-narrow">
              <div className="columns is-mobile">
                <div className="column is-half">
                  <label className="label" htmlFor="widget_date">
                    {reserveCheckIn}
                  </label>
                  <DatePicker
                    dateFormat={loc.dateFormat}
                    locale={loc.datePickerLocale}
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    className="input"
                    id="widget_date"
                    name="widget_date"
                  />
                </div>
                <div className="column is-half">
                  <label className="label" htmlFor="widget_date_to">
                    {reserveCheckOut}
                  </label>
                  <DatePicker
                    dateFormat={loc.dateFormat}
                    locale={loc.datePickerLocale}
                    selected={endDate}
                    onChange={date => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    className="input"
                    id="widget_date_to"
                    name="widget_date_to"
                  />
                </div>
              </div>
            </div>
            <div className="column pt-5">
              <button className="button is-primary mt-4" type="submit">
                {reserveButton}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const FloatingReserveButton = () => {
  const { actionReserve } = useTranslations();
  const { initStart, initEnd } = dates();

  return (
    <div id="floating-reserve">
      <form
        method="POST"
        action="https://hotels.cloudbeds.com/reservas/1rfZCc"
        target="_blank"
      >
        <input type="hidden" name="date_format" value="m/d/Y" />
        <input
          type="hidden"
          name="widget_date"
          value={initStart.toLocaleDateString("en-US")}
        />
        <input
          type="hidden"
          name="widget_date_to"
          value={initEnd.toLocaleDateString("en-US")}
        />
        <button className="button is-primary" type="submit">
          {actionReserve}
        </button>
      </form>
    </div>
  );
};

export { FloatingReserveButton };

export default Reserve;
