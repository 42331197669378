import React from "react";
import Navigation from "./navigation";
import Footer from "./footer";
import { FloatingReserveButton } from "./reserve";

import "./styles.scss";

const LocaleContext = React.createContext();

// Use the built-in Context API to make the "locale" available to every component in the tree
// This e.g. enables the LocalizedLink to function correctly
// As this component wraps every page (due to the wrapPageElement API) we can be sure to have
// the locale available everywhere!
const Layout = ({ children, pageContext: { locale, alternates } }) => (
  <LocaleContext.Provider value={{ locale, alternates }}>
    <Navigation />

    <main role="main">{children}</main>

    <FloatingReserveButton />
    <Footer />
  </LocaleContext.Provider>
);

export { Layout, LocaleContext };
