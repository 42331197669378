import React from "react";

// Structured search data on one place, in json+ld format
// Remember to test the data with: https://search.google.com/structured-data/testing-tool/u/0/
const Organization = {
  "@context": "http://schema.org",
  "@type": "Hotel",
  url: "https://yllaslodge.com",
  address: {
    "@type": "PostalAddress",
    addressCountry: "FI",
    addressLocality: "Äkäslompolo",
    addressRegion: "Lapland",
    postalCode: "FI-95970",
    streetAddress: "Äkäsentie 6",
  },
  description: "Cozy boutique hotel at the heart of Finnish Lapland",
  email: "lodge(at)yllaslodge.com",
  name: "Ylläs Lodge 67˚N",
  alternateName: "Yllaslodge",
  telephone: "+358 40 67 111 67",
  openingHours: "Mo-Su 10:00-16:00",
  paymentAccepted: "Credit Card",
  priceRange: "$100-$370",
  logo: "https://yllaslodge.com/ld-schema-yllas-lodge-logo.png",
  image: "https://yllaslodge.com/ld-schema-yllas-lodge.jpg",
  latitude: "67.604353",
  longitude: "24.168974",
};

// This should be pasted on one page only, no benefit having it on multiple pages
const SEOSnippet = () => (
  <script
    type="application/ld+json"
    dangerouslySetInnerHTML={{ __html: JSON.stringify(Organization) }}
  />
);

export { Organization, SEOSnippet };
