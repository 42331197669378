import React from "react";
import { Link } from "gatsby";
import { LocaleContext } from "./layout";
import locales from "../../config/i18n";

// Use the globally available context to choose which languages to show and hide
const LanSwitcher = ({ className, itemClassName }) => {
  const { locale, alternates } = React.useContext(LocaleContext);

  let navneeded = [];
  Object.entries(locales).forEach(([k, v]) => {
    if (k === locale) {
      // Skip our current locale, no need to change to it
      return;
    }

    navneeded.push(v);
  });

  // Find the specific alternate path for this page, or fallback
  const lanPath = (loc, fallback) =>
    alternates?.find(v => v.hrefLang === loc.toLowerCase())?.path ?? fallback;

  return (
    <div className={className}>
      {navneeded.map(v => (
        <Link
          key={v.siteLanguage}
          to={lanPath(v.locale, "/" + v.path)}
          hrefLang={v.siteLanguage}
          className={itemClassName}
        >
          {v.switchText}
        </Link>
      ))}
    </div>
  );
};

export default LanSwitcher;
