import React from "react";
import { SEOSnippet } from "./structuredData";
import Address from "./address";
import SocialMedia from "./socialmedia";
import { StaticImage } from "gatsby-plugin-image";

const sideBlocksStyle = { paddingTop: "40px", paddingBottom: "20px" };
const Footer = () => {
  return (
    <footer className="footer is-dark">
      <div className="columns">
        <Address
          className="column has-text-right has-text-centered-mobile"
          style={sideBlocksStyle}
        />

        <div
          className="column is-narrow has-text-centered"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        >
          <StaticImage
            src="../images/yllas-lodge-roundlogo.png"
            placeholder="blurred"
            height={260}
            width={260}
            alt="Ylläs Lodge 67N logo"
          />
        </div>

        <SocialMedia
          className="column has-text-centered-mobile"
          style={sideBlocksStyle}
        />
      </div>
      <SEOSnippet />
    </footer>
  );
};

export default Footer;
