exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-area-js": () => import("./../../../src/pages/area.js" /* webpackChunkName: "component---src-pages-area-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-area-js": () => import("./../../../src/pages/en/area.js" /* webpackChunkName: "component---src-pages-en-area-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-facilities-and-amenities-js": () => import("./../../../src/pages/en/facilities-and-amenities.js" /* webpackChunkName: "component---src-pages-en-facilities-and-amenities-js" */),
  "component---src-pages-en-gallery-js": () => import("./../../../src/pages/en/gallery.js" /* webpackChunkName: "component---src-pages-en-gallery-js" */),
  "component---src-pages-en-how-to-travel-to-yllas-js": () => import("./../../../src/pages/en/how-to-travel-to-yllas.js" /* webpackChunkName: "component---src-pages-en-how-to-travel-to-yllas-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-rooms-index-js": () => import("./../../../src/pages/en/rooms/index.js" /* webpackChunkName: "component---src-pages-en-rooms-index-js" */),
  "component---src-pages-en-rooms-suite-js": () => import("./../../../src/pages/en/rooms/suite.js" /* webpackChunkName: "component---src-pages-en-rooms-suite-js" */),
  "component---src-pages-en-rooms-three-bedroom-apartment-js": () => import("./../../../src/pages/en/rooms/three-bedroom-apartment.js" /* webpackChunkName: "component---src-pages-en-rooms-three-bedroom-apartment-js" */),
  "component---src-pages-en-rooms-twin-js": () => import("./../../../src/pages/en/rooms/twin.js" /* webpackChunkName: "component---src-pages-en-rooms-twin-js" */),
  "component---src-pages-en-rooms-twin-with-balcony-js": () => import("./../../../src/pages/en/rooms/twin-with-balcony.js" /* webpackChunkName: "component---src-pages-en-rooms-twin-with-balcony-js" */),
  "component---src-pages-en-terms-and-conditions-js": () => import("./../../../src/pages/en/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-en-terms-and-conditions-js" */),
  "component---src-pages-facilities-and-amenities-js": () => import("./../../../src/pages/facilities-and-amenities.js" /* webpackChunkName: "component---src-pages-facilities-and-amenities-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-how-to-travel-to-yllas-js": () => import("./../../../src/pages/how-to-travel-to-yllas.js" /* webpackChunkName: "component---src-pages-how-to-travel-to-yllas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rooms-index-js": () => import("./../../../src/pages/rooms/index.js" /* webpackChunkName: "component---src-pages-rooms-index-js" */),
  "component---src-pages-rooms-suite-js": () => import("./../../../src/pages/rooms/suite.js" /* webpackChunkName: "component---src-pages-rooms-suite-js" */),
  "component---src-pages-rooms-three-bedroom-apartment-js": () => import("./../../../src/pages/rooms/three-bedroom-apartment.js" /* webpackChunkName: "component---src-pages-rooms-three-bedroom-apartment-js" */),
  "component---src-pages-rooms-twin-js": () => import("./../../../src/pages/rooms/twin.js" /* webpackChunkName: "component---src-pages-rooms-twin-js" */),
  "component---src-pages-rooms-twin-with-balcony-js": () => import("./../../../src/pages/rooms/twin-with-balcony.js" /* webpackChunkName: "component---src-pages-rooms-twin-with-balcony-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

