import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import { GatsbyImage } from "gatsby-plugin-image";

const logoStyle = { marginRight: "10px", marginTop: "3px" };
const igAddr = "https://www.instagram.com/yllaslodge67n/";

const SocialMedia = ({ ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      summer: allFile(
        sort: { name: ASC }
        filter: {
          relativeDirectory: { eq: "footer/summer" }
          sourceInstanceName: { eq: "images" }
        }
      ) {
        edges {
          node {
            ...socialMediaImage
          }
        }
      }
      winter: allFile(
        sort: { name: ASC }
        filter: {
          relativeDirectory: { eq: "footer/winter" }
          sourceInstanceName: { eq: "images" }
        }
      ) {
        edges {
          node {
            ...socialMediaImage
          }
        }
      }
    }
  `);

  const month = new Date().getMonth();
  const currentImgSet =
    month >= 4 && month < 10 ? data.summer.edges : data.winter.edges;

  // We know and trust there are 6 images per set, split to rows
  const imgs = [currentImgSet.slice(0, 3), currentImgSet.slice(3)];

  return (
    <div {...props}>
      <div className="is-hidden-mobile">
        {imgs.map((group, i) => (
          <div key={i} className="columns is-mobile">
            {group.map((img, j) => (
              <div
                key={j}
                className={`column is-narrow ${
                  j === 2 ? "is-hidden-touch" : ""
                }`}
              >
                <a href={igAddr}>
                  <GatsbyImage
                    image={img.node.childImageSharp.gatsbyImageData}
                    alt={img.node.name.replace(/-/g, " ").substring(2)}
                  />
                </a>
              </div>
            ))}
          </div>
        ))}
      </div>

      <a href={igAddr} aria-label="Instagram">
        <InstagramIcon
          fontSize="large"
          style={logoStyle}
          alt="Instagram icon"
        />
      </a>
      <a href="https://www.facebook.com/Lodge67N" aria-label="Facebook">
        <FacebookIcon fontSize="large" style={logoStyle} alt="Facebook icon" />
      </a>
      <a
        href="https://www.tripadvisor.com/Hotel_Review-g930790-d7279265-Reviews-Lodge_67_N-Akaslompolo_Lapland.html"
        aria-label="TripAdvisor"
      >
        <svg
          role="img"
          height="35px"
          width="35px"
          fill="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Tripadvisor icon</title>
          <path d="M12.006 4.295c-2.67 0-5.338.784-7.645 2.353H0l1.963 2.135a5.997 5.997 0 0 0 4.04 10.43 5.976 5.976 0 0 0 4.075-1.6L12 19.705l1.922-2.09a5.972 5.972 0 0 0 4.072 1.598 6 6 0 0 0 6-5.998 5.982 5.982 0 0 0-1.957-4.432L24 6.648h-4.35a13.573 13.573 0 0 0-7.644-2.353zM12 6.255c1.531 0 3.063.303 4.504.903C13.943 8.138 12 10.43 12 13.1c0-2.671-1.942-4.962-4.504-5.942A11.72 11.72 0 0 1 12 6.256zM6.002 9.157a4.059 4.059 0 1 1 0 8.118 4.059 4.059 0 0 1 0-8.118zm11.992.002a4.057 4.057 0 1 1 .003 8.115 4.057 4.057 0 0 1-.003-8.115zm-11.992 1.93a2.128 2.128 0 0 0 0 4.256 2.128 2.128 0 0 0 0-4.256zm11.992 0a2.128 2.128 0 0 0 0 4.256 2.128 2.128 0 0 0 0-4.256z" />
        </svg>
      </a>
    </div>
  );
};

export default SocialMedia;

export const socialMediaImage = graphql`
  fragment socialMediaImage on File {
    id
    name
    childImageSharp {
      gatsbyImageData(width: 80, height: 80)
    }
  }
`;
