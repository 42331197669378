import React from "react";
import { Organization } from "./structuredData";
import useTranslations from "./useTranslations";

const Address = ({ ...props }) => {
  const { footerTel, footerEmail } = useTranslations();

  return (
    <address {...props}>
      <div className="subtitle is-4">{Organization.name}</div>
      {Organization.address.streetAddress} <br />
      {Organization.address.postalCode} {Organization.address.addressLocality}{" "}
      <br />
      {Organization.address.addressRegion}, FINLAND
      <br />
      <br />
      {footerTel} <a href="tel:+358406711167">+358 40 67 111 67</a>
      <br />
      {footerEmail}{" "}
      <a href="mailto:lodge@yllaslodge.com">lodge@yllaslodge.com</a>
    </address>
  );
};

export default Address;
