import React, { useState } from "react";
import LocalizedLink from "./localizedLink";
import LanSwitcher from "./lanSwitcher";
import useTranslations from "./useTranslations";
import { StaticImage } from "gatsby-plugin-image";

const Navigation = () => {
  const {
    navHome,
    navRooms,
    navRoomTwin,
    navRoomTwinWithBalcony,
    navRoomSuite,
    navRoomApartment,
    navFacilities,
    navHowToTravel,
    navGallery,
    navArea,
    navContact,
    navFloorPlan,
    navTerms,
    actionReserve,
  } = useTranslations();
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  return (
    <nav
      className="navbar is-dark"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <LocalizedLink
          to="/"
          aria-label={navHome}
          style={{ padding: "0 0.8rem 0 1rem" }}
          id="main-logo"
        >
          <StaticImage
            src="../images/yllas-lodge-logo-big.png"
            layout="fixed"
            placeholder="blurred"
            height={60}
            alt="Ylläs Lodge 67N logo"
          />
        </LocalizedLink>

        <div className="navbar-item">
          <a
            className="button is-primary"
            href="https://hotels.cloudbeds.com/reservation/1rfZCc"
            target="_blank"
            rel="noopener noreferrer"
            id="reserve"
          >
            {actionReserve}
          </a>
        </div>

        <a
          role="button"
          className={`navbar-burger burger ${open ? "is-active" : ""}`}
          aria-label="menu"
          aria-expanded={open ? "true" : "false"}
          href="#nav"
          onClick={e => {
            e.preventDefault();
            toggle();
          }}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div className={`navbar-menu ${open ? "is-active" : ""}`}>
        <div className="navbar-start">
          <LocalizedLink to="/" className="navbar-item">
            {navHome}
          </LocalizedLink>

          <div className="navbar-item has-dropdown is-hoverable">
            <LocalizedLink to="/rooms" className="navbar-link">
              {navRooms}
            </LocalizedLink>

            <div className="navbar-dropdown">
              <LocalizedLink to="/rooms/twin" className="navbar-item">
                {navRoomTwin}
              </LocalizedLink>
              <LocalizedLink
                to="/rooms/twin-with-balcony"
                className="navbar-item"
              >
                {navRoomTwinWithBalcony}
              </LocalizedLink>
              <LocalizedLink to="/rooms/suite" className="navbar-item">
                {navRoomSuite}
              </LocalizedLink>
              <LocalizedLink
                to="/rooms/three-bedroom-apartment"
                className="navbar-item"
              >
                {navRoomApartment}
              </LocalizedLink>
              <hr className="navbar-divider"></hr>
              <a href="/Lodge-67N-HQ-floor-plan.pdf" className="navbar-item">
                {navFloorPlan}
              </a>
              <LocalizedLink to="/terms-and-conditions" className="navbar-item">
                {navTerms}
              </LocalizedLink>
            </div>
          </div>

          <LocalizedLink to="/facilities-and-amenities" className="navbar-item">
            {navFacilities}
          </LocalizedLink>
          <LocalizedLink to="/gallery" className="navbar-item">
            {navGallery}
          </LocalizedLink>
          <LocalizedLink to="/area" className="navbar-item">
            {navArea}
          </LocalizedLink>
          <LocalizedLink to="/how-to-travel-to-yllas" className="navbar-item">
            {navHowToTravel}
          </LocalizedLink>
          <LocalizedLink to="/contact" className="navbar-item">
            {navContact}
          </LocalizedLink>
        </div>

        <LanSwitcher className="navbar-end" itemClassName="navbar-item" />
      </div>
    </nav>
  );
};

export default Navigation;
